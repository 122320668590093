import { render, staticRenderFns } from "./dictionaryDetail.vue?vue&type=template&id=3849de0d&scoped=true&"
import script from "./dictionaryDetail.vue?vue&type=script&lang=js&"
export * from "./dictionaryDetail.vue?vue&type=script&lang=js&"
import style0 from "./dictionaryDetail.vue?vue&type=style&index=0&id=3849de0d&prod&scoped=true&lang=css&"
import style1 from "./dictionaryDetail.vue?vue&type=style&index=1&id=3849de0d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3849de0d",
  null
  
)

export default component.exports